<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title>Voucher History List</v-card-title>
        <v-card-text>
          <template v-for="(voucher,i) in voucherHistoy">
            <v-card outlined :key="i" class="mb-1">
              <v-card-text class="py-1 px-2 black--text">
                <v-row no-gutters>
                  <v-col cols="4" class="pt-1">Voucher Code : <br> {{voucher.voucherCode}}</v-col>
                  <v-col cols="5" class="pt-1">PNR : <br>{{voucher.pnr}}</v-col>
                  <v-col cols="3" class="text-right pt-2">
                    <v-btn
                      @click="editVoucherHistory(voucher)"
                      v-if="!hideButton"
                      x-small
                      depressed
                      fab
                      color="warning"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    voucherHistoy: Array,
    hideButton: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  methods: {
    editVoucherHistory: function(voucher) {
      this.$emit("editVoucherHistory", voucher);
    }
  }
};
</script>