<template>
  <v-row no-gutters>
    <v-col cols="12" md="8" class="pa-1 pt-2">
      <v-alert border="left" dense text color="primary">VoucherCode: {{voucher.voucherCode}}</v-alert>
      <v-alert class="mt-2" border="left" dense text color="primary">Refund</v-alert>
      <v-data-table
        :mobile-breakpoint="NaN"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
        hide-default-header
        height="63vh"
        :items="voucher.way"
        class="tbl-way"
      >
        <template v-slot:header="{ headers }">
          <thead>
            <tr>
              <th rowspan="2">Date</th>
              <th rowspan="2">Travel Date</th>
              <th rowspan="2">Voucher Status</th>
              <th rowspan="2">Flight</th>
              <th rowspan="2">Class</th>
              <th rowspan="2">Dep</th>
              <th rowspan="2">From</th>
              <th rowspan="2">To</th>
              <th rowspan="2">Pax</th>
              <th rowspan="2">Fare</th>
              <th colspan="2">Amout</th>
              <th rowspan="2">Action</th>
            </tr>
            <tr>
              <th>MMK</th>
              <th>USD</th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }" v-if="voucher.way != null && voucher.way.length > 0">
          <tbody>
            <template v-for="(item, index) in items">
              <template v-if="item.status != 'INACTIVE'">
                <tr :key="index" :class="rowHighlight(item.wayId)">
                  <td>{{item.date}}</td>
                  <td>{{ item.travelDate }}</td>
                  <td>{{item | voucherStatus}}</td>
                  <td>{{ item.flight.flightName }}</td>
                  <td>{{ item.className }}</td>
                  <td>{{ item.dept }}</td>
                  <td>{{ item.fromSector.sectorName }}</td>
                  <td>{{ item.toSector.sectorName }}</td>
                  <td>{{ item.pass }}</td>
                  <td>{{ item.fare }}</td>
                  <td>{{ item.mmkAmount | zeroFilter}}</td>
                  <td>{{ item.usdAmount | zeroFilter}}</td>
                  <td>
                    <v-checkbox v-model="item.checked" dense hide-details class="mt-n1" />
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-data-table>
      <v-btn :disabled="!canSave" @click="saveRefund" color="primary">
        <v-icon>save</v-icon>New Refund
      </v-btn>
      <refund-dialog
        :dialog.sync="refundDialog"
        :refund="refund"
        :voucher="voucher"
        :saveRefund="reloadRefund"
      />
    </v-col>
    <v-col cols="12" md="4" class="pa-1 pt-2">
      <voucher-history :voucherHistoy="voucherHistoy" @editVoucherHistory="editVoucherHistory" />
    </v-col>
  </v-row>
</template>

<script>
import voucherService from "../service/VoucherService";
import HistoryComponent from "../components/HistoryComponent";
import RefundDialog from "../components/RefundDialog";

export default {
  mounted: function() {
    this.reloadRefund();
  },
  data() {
    return {
      voucherHistoy: [],
      headers: [
        {
          text: "Travel Date",
          value: "travelDate"
        },
        {
          text: "Flight",
          value: "flight.flightName"
        },
        {
          text: "Class",
          value: "className"
        },
        {
          text: "Dep",
          value: "dept"
        },
        {
          text: "From",
          value: "fromSector.sectorName"
        },
        {
          text: "To",
          value: "toSector.sectorName"
        },
        {
          text: "Pass",
          value: "pass"
        },
        {
          text: "Fare",
          value: "fare"
        },
        {
          text: "MMK",
          value: "mmkAmount"
        },
        {
          text: "USD",
          value: "usdAmount"
        },
        {
          text: "Action",
          value: "action"
        }
      ],
      voucher: {
        voucherId: 0,
        voucherCode: "",
        forPerson: [
          {
            name: "",
            pass: {
              passType: "", //ADULT,CHILD,INFANT
              passTypeName: ""
            }
          }
        ],
        toCustomer: {
          customerId: 0,
          customerName: "",
          phone: "",
          address: "",
          type: "AGENT"
        },
        pnr: "",
        date: "", //15 Dec 19
        way: [
          // {
          //   travelDate: "16 Dec", //16 Dec
          //   flight: {
          //     flightId: 5,
          //     flightName: "F0004"
          //   },
          //   className: "Low",
          //   dept: "11:10", //11:10
          //   fromSector: {
          //     sectorId: 1,
          //     sectorName: "MDL"
          //   },
          //   toSector: {
          //     sectorId: 2,
          //     sectorName: "YGN"
          //   },
          //   pass: 2,
          //   passType: "ADULT", //CHILD, INFANT
          //   fare: 5,
          //   baseFare: 3,
          //   commissionPercent: 0.0,
          //   commission: 0,
          //   usdTotalCommission: 0,
          //   mmkTotalCommission: 0,
          //   usdAmount: 10,
          //   mmkAmount: 0
          // }
        ],
        usdTotal: 0,
        mmkTotal: 0,
        usdReceive: 0,
        mmkReceive: 0,
        usdBalance: 0,
        mmkBalance: 0,
        mmkDiscount: 0,
        usdDiscount: 0,
        issuedBy: "",
        remark: ""
      },
      refundDialog: false,
      refund: {
        refundCode: "",
        remark: "",
        usdTotal: 0,
        mmkTotal: 0,
        moneyType: "mmk",
        refundWay: [
          //   {
          //     forPerson: "",
          //     pass: 0,
          //     fare: 0,
          //     pnr: "PNR1,PNR2",
          //     usdAmount: 0,
          //     mmkAmount: 0
          //   }
        ]
      }
    };
  },
  methods: {
    getVoucherHistoryChange: function(voucherId) {
      voucherService.getVoucherHistoryChange(voucherId).then(response => {
        console.log(response);
        this.voucherHistoy.splice(0, this.voucherHistoy.length);
        this.voucherHistoy.push(...response);
      });
    },
    editVoucherHistory: function(voucher) {
      console.log("voucher");
      console.log(voucher);
    },
    saveRefund: function() {
      let list = this.voucher.way.filter(value => {
        return value.checked;
      });
      let refundWay = [];
      list.forEach(value => {
        let way = {
          wayId: value.wayId,
          forPerson: "",
          pass: value.pass,
          fare: 0,
          pnr: "",
          amount: 0,
          fromSector: value.fromSector,
          toSector: value.toSector,
          travelDate: value.travelDate
        };
        refundWay.push(way);
      });
      this.refund.refundWay = this.refund.refundWay.filter(f => false);
      this.refund.refundWay.push(...refundWay);
      this.refundDialog = true;
    },
    rowHighlight: function(wayId) {
      let resultClass = "";
      let i = wayId / 3;
      if (i == 0) {
        resultClass = "color1";
      } else if (i == 1) {
        resultClass = "color2";
      } else if (i == 2) {
        resultClass = "color3";
      } else {
        resultClass = "color4";
      }
      return resultClass;
    },
    reloadRefund: function() {
      let voucherId = this.$route.params.voucherId;
      this.getVoucherHistoryChange(voucherId);
      voucherService.getLastVoucherById(voucherId).then(data => {
        data.way.forEach(value => {
          value.checked = false;
        });
        this.voucher = data;
      });
    }
  },
  computed: {
    canSave: function() {
      let list = this.voucher.way.filter(value => {
        return value.checked;
      });

      return list.length > 0;
    }
  },
  components: {
    "refund-dialog": RefundDialog,
    "voucher-history": HistoryComponent
  },
  filters: {
    zeroMinusFilter: function(value) {
      if (value == 0) {
        return "";
      } else {
        return -Math.abs(value);
      }
    },
    zeroFilter: function(value) {
      if (value == 0) {
        return "";
      }
      return value;
    },
    voucherStatus: function(value) {
      if (value.status == "S") {
        return "SectorChange";
      } else if (value.status == "D") {
        return "DateChange";
      } else if (value.status == "N") {
        return "NameChange";
      } else {
        if (value.code.includes("AR")) {
          return "Refund";
        }
        return "OriginVoucher";
      }
    }
  }
};
</script>

<style scoped>
.tbl-way table,
.tbl-way td,
.tbl-way th {
  padding: 4px;
}

.tbl-way thead td {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-way thead th {
  font-size: 14px;
  padding: 2px;
  height: 24px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-way tbody th,
.tbl-way tbody td {
  font-size: 13px;
  padding-left: 3px;
  border: 1px solid #e7e7e7;
  padding-right: 1px;
}
.row-shadow {
  box-shadow: inset 0px 2px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px -2px 8px -5px rgba(50, 50, 50, 0.75);
}
</style>